@import '../../../../scss/theme-bootstrap';

.whiteout {
  visibility: hidden;
}

.voice-search {
  @include font--text();
  position: absolute;
  width: 100%;
  top: 2.25rem;
  .fade-out {
    animation: fadeOut 0.35s ease-in-out forwards;
    z-index: -5;
  }
  .fade-in {
    animation: fadeIn 0.35s ease-in-out forwards;
    z-index: auto;
  }
  &__tooltip {
    position: absolute;
    width: 100%;
    top: 0.3rem;
    animation: fadeIn 0.35s ease-in-out forwards;
    &--small {
      .voice-search__tooltip-smallbox {
        display: block;
      }
    }
    &--big {
      .voice-search__tooltip-bigbox {
        display: block;
      }
    }
    &--full {
      .voice-search__tooltip-full {
        display: block;
      }
    }
  }
  &__mic-highlight {
    position: absolute;
    border-radius: 50%;
    animation: fadeIn 1s infinite ease-in-out;
    box-shadow: 0 1px 20px 0 $color-box-shadow;
    top: 0.05rem;
    right: 15%;
    width: 1.25rem;
    height: 1.25rem;
  }
  &__tooltip-smallbox,
  &__tooltip-bigbox {
    text-align: center;
    font-size: 13px;
    display: none;
    &.green {
      background: $color-green;
      color: $color-white;
    }
    &.black {
      background: $color-black;
      color: $color-white;
    }
    p {
      margin: 0;
    }
    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-style: solid;
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent $color-green transparent;
      right: 5%;
      top: -0.4rem;
    }
  }
  &__tooltip-smallbox {
    padding: 0.1rem 0rem 0.3rem;
    margin: 0 1rem;
  }
  &__tooltip-bigbox {
    padding: 1rem 1rem 1.5rem;
    &__close {
      position: absolute;
      color: $color-white;
      text-decoration: none;
      right: 1.5rem;
      bottom: 0.5rem;
    }
  }
  &__tooltip-full {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    color: $color-white;
    opacity: 0.75;
    &.black {
      background: $color-black;
    }
    &.blue {
      background: $color-blue;
    }
    &--inner {
      position: absolute;
      text-align: center;
      font-family: arial;
      font-weight: bold;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $color-white;
      background: $color-black;
      font-size: 13px;
    }
    &__title {
      top: 35%;
      width: 100%;
      font-size: 18px;
      letter-spacing: 1px;
    }
    &__body {
      top: 42%;
      width: 50%;
    }
    &__close {
      padding: 0.5rem 0;
      top: 51%;
      left: 50%;
      width: 30%;
    }
  }
  .search-form__mic {
    position: absolute;
    top: -1.75rem;
    right: 3%;
    width: 2rem;
    height: 2rem;
    text-align: center;
    border: solid 1px transparent;
    border-radius: 50%;
    .icon--microphone {
      height: 1rem;
      width: 1rem;
      margin-bottom: 20%;
    }
  }
  &__overlay {
    position: relative;
    background: $color-white;
    padding-top: 0.5rem;
    width: 100%;
    height: 100vh;
  }
  &__overlay-top,
  &__overlay-title {
    text-align: center;
    color: $color-black;
    letter-spacing: 1px;
    font-family: $font--lamer-text;
  }
  &__overlay-top {
    position: absolute;
    top: 1.75rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-size: 0.9rem;
    font-style: italic;
  }
  &__overlay-title {
    font-weight: bold;
    font-size: 1.5rem;
  }
  &__overlay-retry-message {
    text-align: center;
    color: $color-black;
    letter-spacing: 1px;
    font-weight: normal;
    font-family: $font--lamer-text;
    font-size: 1rem;
  }
  &__overlay-close {
    position: absolute;
    right: 5%;
    width: 1.2rem;
    height: 1.2rem;
    top: -1.75rem;
    .icon--close {
      height: 100%;
      width: 100%;
    }
  }
  @keyframes scaleIn {
    0% {
      transform: scale(1, 1);
      opacity: 0.75;
    }
    100% {
      transform: scale(3, 3);
      opacity: 0;
    }
  }
  @-webkit-keyframes scaleIn {
    0% {
      -webkit-transform: scale(1, 1);
      -webkit-opacity: 0.75;
    }
    100% {
      -webkit-transform: scale(3, 3);
      -webkit-opacity: 0;
    }
  }
  @keyframes fadeIn {
    0% {
      visibility: hidden;
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      -webkit-visibility: hidden;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-visibility: visible;
      -webkit-opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      visibility: visible;
      opacity: 1;
    }
    100% {
      visibility: hidden;
      opacity: 0;
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      -webkit-visibility: hidden;
      -webkit-opacity: 0;
    }
    100% {
      -webkit-visibility: visible;
      -webkit-opacity: 1;
    }
  }
  &__animation {
    text-align: center;
    .voice-search__animation-mic {
      width: 4rem;
      height: 4rem;
      &.animation-stop {
        border: solid 1px $color-black;
        border-radius: 0.25rem;
        .voice-search__animation-circle {
          animation: none;
        }
      }
    }
    .voice-search__animation-circle {
      animation: scaleIn 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
      position: absolute;
      height: 4rem;
      width: 4rem;
      &--one {
        animation-delay: -3s;
      }
      &--two {
        animation-delay: -2s;
      }
      &--three {
        animation-delay: -1s;
      }
      &--four {
        animation-delay: 0s;
      }
    }
    .voice-search__animation-search {
      width: 4rem;
      height: 4rem;
      margin: 4rem auto;
      position: relative;
      overflow: hidden;
      > img {
        height: 100%;
        width: auto;
      }
    }
    &--listening {
      .voice-search__animation-mic {
        display: block;
      }
      .voice-search__animation-search {
        display: none;
      }
    }
    &--searching {
      .voice-search__animation-mic {
        display: none;
      }
      .voice-search__animation-search {
        display: block;
      }
    }
  }
  &__animation-circle {
    border-radius: 50%;
    background-color: $color-green;
    opacity: 0;
  }
  &__animation-mic {
    position: relative;
    height: 2rem;
    margin: 6.5em auto 3.5em;
    .icon--microphone {
      position: absolute;
      transform: translate(-50%, 0%);
      height: 4rem;
      width: 4rem;
      padding: 1.25rem;
      background: $color-white;
      border-radius: 50%;
    }
  }
  &__overlay-transcription {
    padding: 0 0 0.5rem;
    margin: 1.25rem 1rem 0rem 1rem;
    border-bottom: solid 1px $color-black;
    color: $color-dark-grey;
    letter-spacing: 1px;
    font-family: $font--lamer-text;
    font-style: italic;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
  &__overlay-examples {
    margin: 0 1rem;
    li p {
      margin: 0.4rem 0 0 0;
      padding: 1rem 2rem;
      border-bottom: solid 1px $color-light-gray;
      font-size: 0.85rem;
      letter-spacing: 1px;
      color: $color-grey;
      font-family: $font--lamer-text;
      text-align: center;
      text-transform: uppercase;
    }
  }
}
